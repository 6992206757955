<template>
    <div>
        <div class="isheader">
             <span class="is-span-header go" @click="$router.push('/guide')"></span>
             <span class="is-span-header">到场质检</span>
             <span class="is-span-header" @click="islogout">退出</span>
        </div>
        <div class="contenta">
            <van-grid :column-num="1">
                <van-grid-item to="/AddQuality" icon="add-o" text="新增运输批次" />
                <van-grid-item to="/TransportationBatch" icon="records" text="选择运输批次拍照" />
                <van-grid-item to="/QualityInspectionConfirmed" icon="orders-o" text="到场质检确认" />
                <!-- <van-grid-item to="/ConfirmPurchasePrice" icon="refund-o" text="确认采购价格" /> -->
                <van-grid-item to="/MyShopping" icon="contact" text="我的采购" />
            </van-grid>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.contenta{
    padding-top: 10px;
    margin-top: 50px;
   
}

/deep/.van-grid-item__text {
  font-size: 18px;
}
</style>